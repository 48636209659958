import { apiPost } from '@/utils/api';
import errorPopup from '@/utils/error-popup';

export default (params) => {
	return apiPost(`password_change`, params, {
		validateStatus: function(status) {
			return status >= 200 && status < 300 || status === 422;
		}
	})
		.then((response => response))
		.catch(error => errorPopup(error));
}
