<template>
	<div class="row">
		<div v-if="subscription" class="col-12">
			<div class="card">
				<div class="card-body">
					<h4 class="card-title">Subscription Details</h4>

					<b-form-group
						label-cols="10"
						label-cols-sm="2"
						label="Status"
						label-for="subscriptionStatus"
					>
						<b-form-input 
							id="subscriptionStatus" 
							v-model="subscriptionStatus"
							:disabled="true"
						>
						</b-form-input>
					</b-form-group>

					<b-form-group
						v-if="subscriptionStatus === 'Trial'"
						label-cols="10"
						label-cols-sm="2"
						label="Trial End Date"
						label-for="trial-end-date"
					>
						<b-form-input 
							id="trial-end-date" 
							v-model="formattedTrialEndsAt"
							:disabled="true"
						>
						</b-form-input>
					</b-form-group>

					<b-form-group
						v-if="subscription.price"
						label-cols="10"
						label-cols-sm="2"
						label="Cost"
						label-for="cost"
					>
						<b-form-input 
							id="cost" 
							v-model="formattedPrice"
							:disabled="true"
						>
						</b-form-input>
					</b-form-group>

					<b-form-group
						v-if="subscription.nextBillDate"
						label-cols="10"
						label-cols-sm="2"
						label="Next Bill Date"
						label-for="next-bill-date"
					>
						<b-form-input 
							id="next-bill-date" 
							v-model="formattedNextBillDate"
							:disabled="true"
						>
						</b-form-input>
					</b-form-group>
				</div>
			</div>
		</div> <!-- end col-12 -->

		<div 
			v-if="subscription && !subscription.isActive && !subscription.isCanceled"
			class="col-12"
		>
			<div class="card">
				<div class="card-body">
					<h4 class="card-title">Start Subscription</h4>
					<p>Select a monthly or annual subscription to for unlimited projects, time entries, and tags.</p>
					<div class="button-container">
						<b-button 
								variant="success"
								@click.prevent="goToStripeCheckout('month')"
							>
							Start Monthly Subscription (£5 per month)
						</b-button>	
						<b-button 
								variant="success"
								@click.prevent="goToStripeCheckout('year')"
							>
							Start Annual Subscription (£50 per year)
						</b-button>						
					</div>
				</div>
			</div>
		</div>

		<div
			v-else-if="subscription && (subscription.isActive && !subscription.isCanceled)"
			class="col-12"
		>
			<div class="card">
				<div class="card-body">
					<h4 class="card-title">Manage Subscription</h4>
					<p>Click the button below to update your payment method, switch to a different plan or cancel your subscription.</p>
					<b-button 
							variant="primary"
							@click.prevent="goToStripePortal"
						>
						Manage Subscription
					</b-button>		
				</div>
			</div>
		</div>

		<div
			v-else-if="subscription && subscription.isCanceled"
			class="col-12"
		>
			<div class="card">
				<div class="card-body">
					<h4 class="card-title">Resume Subscription</h4>
					<b-alert
						v-if="subscription.isOnGracePeriod"
						show
						class="mt-4 text-center"
						variant="warning"
					>Your subscription will expire at the end of the current billing period. Click the button below to resume it.</b-alert>
					<b-alert
						v-else-if="subscription.isEnded"
						show
						class="mt-4 text-center"
						variant="danger"
					>Your subscription has expired. Click the button below to resume it.</b-alert>
					<b-button 
							variant="primary"
							@click.prevent="goToStripePortal"
						>
						Resume Subscription
					</b-button>		
				</div>
			</div>
		</div>		

	</div>
</template>

<script>
import BillingService from '@/services/billing';

export default {
	name: 'SubscriptionDetails',
	
	data() {
		return {
			subscription: null,
		};
	},

	computed: {
		userInfo() {
			return this.$store.state.user.userInfo;
		},
		formattedPrice() {
			return '£' + Number(this.subscription.price / 100).toFixed(2);
		},
		formattedTrialEndsAt() {
			return this.$moment(this.subscription.trialEndsAt).format('MMMM Do YYYY, h:mm:ss a') + ' (UTC)';
		},
		formattedNextBillDate() {
			return this.$moment(this.subscription.nextBillDate).format('MMMM Do YYYY');
		},
		subscriptionStatus() {
			if (this.subscription.isActive && !this.subscription.isCanceled) {
				return 'Active';
			} else if (this.subscription.isActive && this.subscription.isCanceled) {
				return 'Will not renew';
			} else if (!this.subscription.isActive && this.subscription.isCanceled) {
				return 'Canceled';
			} else if (this.subscription.isOnTrial) {
				return 'Trial';
			} else {
				return 'Inactive';
			}
		},
	},

	methods: {
		async goToStripeCheckout(period) {
			const url = await BillingService.getStripeCheckoutUrl(period);
			if (url) window.location = url;
		},

		async goToStripePortal() {
			const url = await BillingService.getStripePortalUrl();
			if (url) window.location = url;
		}
	},

	watch: {
		userInfo(data) {
			this.subscription = data.subscription;
		}
	}

}
</script>

<style lang="scss" scoped>
.button-container {
	button {
		margin-right: 15px;
	}
}
</style>