import { apiGet } from '@/utils/api';
import errorPopup from '@/utils/error-popup';

export default (period) => {
	return apiGet('billing/user/stripe-checkout', { 
		params: {
			period,
		},
	 })
		.then((response => response.data.url))
		.catch(error => errorPopup(error));
}
