<!-- eslint-disable -->
<template>
	<Layout>
		<PageHeader :title="title" />
		<div class="row">
			<div class="col-12">
				<div class="card">
					<div class="card-body">
						<h4 class="card-title">User Details</h4>

						<b-form-group
							label-cols="10"
							label-cols-sm="2"
							label="First Name"
							label-for="first-name"
						>
							<b-form-input 
								id="first-name" 
								v-model="userDetails.firstName"
							/>
						</b-form-group>

						<b-form-group
							label-cols="10"
							label-cols-sm="2"
							label="Last Name"
							label-for="last-name"
						>
							<b-form-input 
								id="last-name" 
								v-model="userDetails.lastName"
							>
							</b-form-input>
						</b-form-group>


						<b-form-group
							label-cols="10"
							label-cols-sm="2"
							label="Company"
							label-for="company"
						>
							<b-form-input 
								id="company" 
								v-model="userDetails.company"
							>
							</b-form-input>
						</b-form-group>						

						<b-form-group
							label-cols="10"
							label-cols-sm="2"
							label="Email"
							label-for="email"
						>
							<b-form-input 
								type="email" 
								id="email"
								:class="{ 'is-invalid': $v.userDetails.email.$error }"
								v-model="userDetails.email"
								@input="$v.userDetails.email.$touch"
							>
							</b-form-input>
							<div 
								class="invalid-feedback" 
								v-if="!$v.userDetails.email.required && $v.userDetails.email.$dirty">Field is required
							</div>
							<div 
								class="invalid-feedback" 
								v-else-if="!$v.userDetails.email.email && $v.userDetails.email.$dirty"> This is not a valid email
							</div>
						</b-form-group>

						<b-button variant="primary" @click.prevent="updateUserDetails">Save</b-button>
					</div>
				</div>
			</div>
			<!-- end col -->

			<div class="col-12">
				<div class="card">
					<div class="card-body">
						<h4 class="card-title">Password</h4>

						<b-form-group
							label-cols="10"
							label-cols-sm="2"
							label="Current Password"
							label-for="current-password"
						>
							<b-form-input 
								type="password" 
								id="current-password" 
								:class="{ 'is-invalid': $v.password.currentPassword.$error }"
								v-model="password.currentPassword"
							>
							</b-form-input>
							<div class="invalid-feedback" v-if="!$v.password.currentPassword.required && $v.password.currentPassword.$dirty">Field is required</div>
							<div class="invalid-feedback" v-if="!$v.password.currentPassword.minLength">Must be at least 8 characters</div>
							<div class="invalid-feedback" v-if="!$v.password.currentPassword.maxLength">Maximum 30 characters</div>							
						</b-form-group>

						<b-form-group
							label-cols="10"
							label-cols-sm="2"
							label="New Password"
							label-for="new-password"
						>
							<b-form-input 
								type="password" 
								id="new-password" 
								:class="{ 'is-invalid': $v.password.newPassword.$error }"
								v-model="password.newPassword"
							>
							</b-form-input>
							<div class="invalid-feedback" v-if="!$v.password.newPassword.required && $v.password.newPassword.$dirty">Field is required</div>
							<div class="invalid-feedback" v-if="!$v.password.newPassword.minLength">Must be at least 8 characters</div>
							<div class="invalid-feedback" v-if="!$v.password.newPassword.maxLength">Maximum 30 characters</div>							
						</b-form-group>

						<b-form-group
							label-cols="10"
							label-cols-sm="2"
							label="Confirm New Password"
							label-for="confirm-new-password"
						>
							<b-form-input 
								type="password" 
								id="confirm-new-password" 
								:class="{ 'is-invalid': $v.password.confirmNewPassword.$error }"
								v-model="password.confirmNewPassword"
							>
							</b-form-input>
							<div class="invalid-feedback" v-if="!$v.password.confirmNewPassword.required && $v.password.confirmNewPassword.$dirty">Field is required</div>
							<div class="invalid-feedback" v-if="!$v.password.confirmNewPassword.sameAsPassword && $v.password.confirmNewPassword.$dirty">Passwords must match.</div>
							<div class="invalid-feedback" v-if="!$v.password.confirmNewPassword.minLength">Must be at least 8 characters</div>
							<div class="invalid-feedback" v-if="!$v.password.confirmNewPassword.maxLength">Maximum 30 characters</div>							
						</b-form-group>

						<b-button 
							variant="primary"
							@click.prevent="updatePassword"
						>
							Save
						</b-button>				
					</div>
				</div>
			</div>
			<!-- end col -->
		</div> <!-- end row -->

		<SubscriptionDetails />

		<div class="row">
			<div class="col-12">
				<div class="card">
					<div class="card-body">
						<h4 class="card-title">Email Verification</h4>

						<b-form-group
							label-cols="10"
							label-cols-sm="2"
							label="Status"
							label-for="email-verification-status"
						>
							<b-form-input 
								id="email-verification-status" 
								v-model="emailVerification.verificationStatus"
								:disabled="true"
							>
							</b-form-input>

							<ResendEmailVerification 
								v-if="emailVerification.verificationStatus === 'Unverified'"
							/>
						</b-form-group>

						<b-form-group
							v-if="emailVerification.verificationStatus === 'Verified'"
							label-cols="10"
							label-cols-sm="2"
							label="Verified At"
							label-for="email-verified-at"
						>
							<b-form-input 
								id="email-verified-at" 
								v-model="emailVerification.verifiedAt"
								:disabled="true"
							>
							</b-form-input>
						</b-form-group>
					</div>
				</div>
			</div>
			<!-- end col -->
		</div>
		<!-- end row -->
	</Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import errorPopup from '@/utils/error-popup';
import ResendEmailVerification from '@/components/client-dashboard/resend-email-verification';
import SubscriptionDetails from '@/components/client-dashboard/subscription-details';

import PasswordService from '@/services/password';
import Swal from "sweetalert2";
import UsersService from '@/services/users';
import { required, sameAs, email, minLength, maxLength } from 'vuelidate/lib/validators'

export default {
	page: {
		title: "Settings",
		meta: [{ name: "Settings", content: appConfig.description }]
	},
	name: 'Settings',
	components: { 
		Layout,
		PageHeader,
		ResendEmailVerification,
		SubscriptionDetails,
	},
	data() {
		return {
			title: "Settings",
			userDetails: {
				firstName: null,
				lastName: null,
				company: null,
				email: null,
			},
			password: {
				currentPassword: null,
				newPassword: null,
				confirmNewPassword: null,
			},
			emailVerification: {
				verificationStatus: null,
				verifiedAt: null,
			},
		};
	},
	computed: {
		userInfo() {
			return this.$store.state.user.userInfo;
		},
	},
	watch: {
		userInfo(data) {
			this.userDetails.firstName = data.firstName;
			this.userDetails.lastName = data.lastName;
			this.userDetails.company = data.company;
			this.userDetails.email = data.email;
			this.emailVerification.verificationStatus = data.emailVerifiedAt === null ? 'Unverified' : 'Verified';
			this.emailVerification.verifiedAt = data.emailVerifiedAt;
			this.subscriptionDetails = data.subscriptionDetails;
		}
	},
	mounted() {
		this.$store.dispatch('user/refreshUserInfo');
	},
	methods: {
		async updateUserDetails() {
			this.$v.userDetails.$touch()

			if (this.$v.userDetails.$invalid) {
				Swal.fire({
					icon: "error",
					title: "Whoops!",
					html: 'Please make sure all fields are filled in correctly.',
				});
				return;
			}

			const payload = {
				first_name: this.userDetails.firstName,
				last_name: this.userDetails.lastName,
				company: this.userDetails.company,
				email: this.userDetails.email,
			}

			await UsersService.updateUser(this.userInfo.id, payload)
				.then((response) => {
					if (typeof response !== 'undefined') { // 422 produces an undefined response
						Swal.fire({
							icon: "success",
							title: "Success!",
							html: `User details updated.`
						});
						this.$store.dispatch('user/refreshUserInfo');
					}
				})
				.catch(error => errorPopup(error));
		},
		async updatePassword() {
			this.$v.password.$touch()

			if (this.$v.password.$invalid) {
				Swal.fire({
					icon: "error",
					title: "Whoops!",
					html: 'Please make sure all fields are filled in correctly.',
				});
				return;
			}

			await PasswordService.updatePassword({
				current_password: this.password.currentPassword,
				new_password: this.password.newPassword,
			})
				.then((response) => {
					if (response.status === 200) {
						Swal.fire({
							icon: "success",
							title: "Success!",
							html: `Password updated.`
						});
					} else { // handle 422
						Swal.fire({
							icon: "error",
							title: "Whoops!",
							html: response.data,
						});
					}
				})
				.catch(error => errorPopup(error));
		}
	},
	validations: {
		userDetails: {
			email: { required, email },
		},
		password: {
			currentPassword: { 
				required,
				minLength: minLength(8),
				maxLength: maxLength(30),
			},
			newPassword: { 
				required,
				minLength: minLength(8),
				maxLength: maxLength(30),
			},
			confirmNewPassword: { 
				required,
				minLength: minLength(8),
				maxLength: maxLength(30),
				sameAsPassword: sameAs('newPassword'),
			},
		},
		validationGroup: ['userDetails', 'password'],
	},	
};
</script>

<style lang="scss">
.resend-email-verification {
	margin-top: 5px;
}
</style>