<template>
	<div>
		<div 
			v-if="state === 'initial'"
			class="mt-1"
			@click="sendEmail"
		>
			<a
				class="link"
				@click.prevent="sendEmail"
			>
				Resend verification email
			</a>
		</div>

		<div 
			v-else-if="state === 'sending'"
			class="mt-1"
		>
			<b-spinner
				label="Spinning"
				variant="primary"
				class="m-1"
			/>
		</div>

		<span v-else-if="state === 'success'">
			<b-alert
				show
				class="mt-4 text-center"
			>Email sent! Please check your inbox.</b-alert>
		</span>

		<span v-else-if="state === 'failed'">
			<b-alert
				show
				variant="danger"
				class="mt-4 text-center"
			>Email send failed! Please try again later or contact support if the problem persists.</b-alert>
		</span>
	</div>
</template>

<script>
import EmailVerificationService from '@/services/email-verification';

export default {
	name: 'ResendEmailVerification',
	
	data() {
		return {
			state: 'initial',
		}
	},

	methods: {
		async sendEmail() {
			this.state = 'sending';
			await EmailVerificationService.sendVerificationEmail()
				.then(response => this.state = 'success')
				.catch(error => this.state = 'failed')
		}
	}
}
</script>

<style lang="scss" scoped>
.link {
	cursor: pointer;
}
</style>